<template>
    <div v-if="accountProducts.length">
        <styled-tooltip
            position="top">
            <template #content>
                <ul class="list">
                    <li
                        v-for="(detail, index) in productDetails"
                        :key="index">
                        <icon
                            class="mr-2"
                            :name="detail.channel"
                            color="#B8C2C7"
                            size="20" />
                        <span>{{ detail.products }}</span>
                    </li>
                </ul>
            </template>
            <span
                class="text-link"
                href="#"
                @click.prevent="() => false">
                {{ accountProducts.length }}
            </span>
        </styled-tooltip>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import StyledTooltip from '@/components/globals/StyledTooltip';
import { PLATFORM_MAPPING } from '@/helpers/globals';

export default {
    components: {
        Icon,
        StyledTooltip
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            meta: { channel: 'meta', products: [] },
            tiktok: { channel: 'tiktok', products: [] },
            google: { channel: 'google', products: [] },
            snapchat: { channel: 'snapchat', products: [] },
            pinterest: { channel: 'pinterest', products: [] },
            microsoft: { channel: 'microsoft', products: [] },
        };
    },
    computed: {
        accountProducts() {
            return this.item.products?.data ?? [];
        },
        productDetails() {
            const channelsDetails = [];
            const accountChannels = new Set();
            this.accountProducts.forEach(product => {
                const platformKey = PLATFORM_MAPPING[product.platform_id];
                if (platformKey) {
                    const platform = this[platformKey];
                    accountChannels.add(platform.channel);
                    platform.products.push(product.display_name);
                }
            });
            Array.from(accountChannels).forEach(channel => {
                channelsDetails.push({
                    channel,
                    products: this[channel].products.join(', ')
                });
            });
            return channelsDetails;
        }
    },
    watch: {
        productDetails() {
            // clear arrays after each change to avoid bugs
            this.meta.products = [];
            this.tiktok.products = [];
            this.google.products = [];
            this.snapchat.products = [];
            this.pinterest.products = [];
            this.microsoft.products = [];
        }
    }
};
</script>

<style lang="scss" scoped>
.list {
    list-style-type: none;
    padding: 0;
    li {
        padding-bottom: 10px;
        display: block;
    }
}
</style>
